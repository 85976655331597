import React from 'react';
import style from './index.module.scss';

import { Keyframes, animated, config  } from 'react-spring/renderprops'
import delay from "delay";

function StockColumn(props) {
  const Sidebar = Keyframes.Spring(async next => {
    await delay(props.timing * 300)
    await next({opacity: 1.0, from: { opacity: 0.0 }, config: config.stiff})
    await delay((props.length - props.timing) * 300)
    props.trigger(props.timing)
  });

  return (
    <div className={style.StockColumn}>
      <Sidebar className={style.StockColumn} native>{ (state) => (
        <animated.div
          className={style.StockUp}
          style={{
            marginTop: props.top + 'px',
            height: props.height + 'px',
            background: ((props.highlight)?'#BD93F9':((props.ticker == 'up')?'#46D3A8':'#F54840')),
            ...state
          }} />
      )}</Sidebar>
    </div>
  );
}

export default StockColumn;
