import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ArticlePage from './ArticlePage';
import BlogHome from './BlogHome';
import ErrorPage from '../ErrorPage';
import TopicPage from './TopicPage';

import articles from '../../Data/registry';


const kHomePath = '/blog/'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class Blog extends React.Component {
  getTopics() {
    const topics = articles.map((x)=>
      x.topic()
    );
    return topics.filter(onlyUnique);
  }

  getTopicRouter() {
    const topics = this.getTopics();
    return topics.map((x, i) => { 
      const total_path = kHomePath + x.route()
      return (
        <Route exact path={total_path} key={"blog_" + i} render={(props) => (
          <TopicPage topic={x} articles={articles} {...props}/>
        )}/>
      );
    });
  }

  getArticleRouter() {
    return articles.map((x, i) => { 
      const total_path = kHomePath + x.route()
      return (
        <Route exact path={total_path} key={"blog_" + i} render={(props) => (
          <ArticlePage article={x} {...props}/>
        )}/>
      );
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/blog" render={(props) => (
            <BlogHome {...props} />
          )} />
          {this.getTopicRouter()}
          {this.getArticleRouter()}
          <Route render={(props) => (
            <ErrorPage {...props} />
          )}/>
        </Switch>
      </Router>   
    );
  }
}

export default Blog;