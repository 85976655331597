import React from 'react';
import style from './index.module.scss';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import Bar from '../Bar';

function GoodRoom() {
  return (
    <div className={style.GoodRoom}>
      <BrowserView>
        <div className={style.GoodRoomTitle}>
          KNOW WHAT YOU ARE GOOD AT
        </div>
        <Bar
          text={"ENERGY"}
          color={"#9D6BFF"}
          size={79}/>
        <Bar
          text={"FINANCIALS"}
          color={"#AE85FF"}
          size={73}/>
        <Bar
          text={"INDUSTRIALS"}
          color={"#BF9EFF"}
          size={75}/>
      </BrowserView>
      <MobileView>
        <div className={style.GoodRoomMobileTitle}>
          KNOW WHAT YOU ARE GOOD AT
        </div>
        <Bar
          text={"ENERGY"}
          color={"#9D6BFF"}
          size={79}/>
        <Bar
          text={"FINANCIALS"}
          color={"#AE85FF"}
          size={73}/>
        <Bar
          text={"INDUSTRIALS"}
          color={"#BF9EFF"}
          size={75}/>
      </MobileView>
    </div>
  );
}

export default GoodRoom;
