import React from "react";
import { useSpring, animated } from 'react-spring'

import style from "./index.module.scss";
import stars from "./assets/stars.svg";

import AppStock from "../../Components/AppStock";
import PhoneStock from "../../Components/PhoneStock";

import {
  BrowserView,
  MobileView
} from "react-device-detect";


const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / -50}px,${y / -50}px,0)`
const trans2 = (x, y) => `translate3d(${x / -80}px,${y / -80}px,0)`

function Banner() {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }));

  return (
    <div className={style.Banner} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <div className={style.BannerCont}>
        <div
          className={style.BannerTitle}
          style={{
            position: "absolute",
            top:"20px",
            left:"30px"
          }}>
          <animated.div style={{transform: props.xy.interpolate(trans2)}}>
            TRADER<span className={style.BannerTitleHighlight}>THOUGHT</span>
          </animated.div>
        </div>
        <div style={{position: "absolute"}}>
          <animated.div 
               style={{
                  width: "100vw",
                  height: "100vh",
                  backgroundRepeat: "repeat",
                  backgroundImage:
                    "url("+stars+")",
                  backgroundSize: "cover",
                  transform: props.xy.interpolate(trans1)
                }}>
            </animated.div>
        </div>
        <div style={{position: "absolute"}}>
          <BrowserView>
            <AppStock />
          </BrowserView>
          <MobileView>
            <PhoneStock />
          </MobileView>
        </div>
      </div>
    </div>
  );
}

export default Banner;
