import React from 'react';
import style from './App.module.scss';

import {
  Parallax,
  ParallaxLayer
} from 'react-spring/renderprops-addons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Blog from './Pages/Blog';
import HomePage from './Pages/HomePage';
import ErrorPage from './Pages/ErrorPage';
import Explorer from './Pages/Explorer';
import PrivatePolicy from './Pages/PrivatePolicy';
import TermsAndConditions from './Pages/TermsAndConditions';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => (
            <HomePage {...props} />
          )} />
          <Route exact path="/private_policy" render={(props) => (
            <PrivatePolicy {...props} />
          )} />
          <Route exact path="/terms_of_service" render={(props) => (
            <TermsAndConditions {...props} />
          )} />
          <Route path="/blog" render={(props) => (
            <Blog {...props} />
          )} />
          <Route path="/explorer" render={(props) => (
            <Explorer {...props} />
          )} />
          <Route render={(props) => (
            <ErrorPage {...props} />
          )}/>
        </Switch>
      </Router>
    );
  }
}

export default App;
