import React from 'react';
import { BlogTemplate } from '../templates'

import { Authors } from '../authors';
import Topics from '../topics';
import Tags from '../tags';

import Paragraph from '../tools/Paragraph';

class GMEInsanity extends BlogTemplate {
  constructor() {
    super()
  }

  route() {
    return 'gme_insanity';
  }

  author() {
    return Authors.ROMI_EKLUND
  }

  title() {
    return 'Opportunities';
  }

  topic() {
    return Topics.CASE_STUDY;
  }

  tags() {
    return [ Tags.INTERMEDIATE ];
  }

  summary() {
    return 'Something';
  }

  text() {
    return (
      <Paragraph text={"Here is the beast of the article, this"}/>
    );
  }
}

export default GMEInsanity;