class Tags {
  name() {
    throw new Error("Name method needs to be implemented");
  }

  summary() {
    throw new Error("Summary method needs to be implemented");
  }
}

class Novice extends Tags {
  name() {
    return 'Novice';
  }

  summary() {
    return 'If you\'re just getting into stocks'
  }
}

class Intermediate extends Tags {
  name() {
    return 'Intermediate';
  }

  summary() {
    return 'If you\'ve been messing around with stocks for a bit'
  }
}

class Expert extends Tags {
  name() {
    return 'Intermediate';
  }

  summary() {
    return 'If you know what you\'re doing'
  }
}

const TagLabel = Object.freeze({
  NOVICE: new Novice(),
  INTERMEDIATE: new Intermediate(),
  EXPERT: new Expert()
});

export default TagLabel;