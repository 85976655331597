import React from 'react';
import style from './index.module.scss';

import Articles from '../Articles';
import Title from '../Title';

import arrowLeft from './assets/arrowLeft.svg';

class TopicPage extends React.Component {
  goHome() {
    window.location = "http://localhost:3001/blog";
  }

  filteredArticles() {
    const current_topic_route = this.props.topic.route();
    return this.props.articles.filter(function(item) {
      return item.topic().route() == current_topic_route;
    });
  }

  render() {
    return (
      <div className={style.TopicPageCont}>
        <div className={style.ArticleArrow}
             onClick={this.goHome.bind(this)}>
          <img src={arrowLeft} />
        </div>
        <div className={style.TopicPage}>
          <Title subtitle={this.props.topic.name().toUpperCase()}/>
          <Articles history={this.props.history} articles={this.filteredArticles()}/>
        </div>
      </div>
    )
  }
}

export default TopicPage;