import React from 'react';
import style from './index.module.scss';

class Topics extends React.Component {
  goTo(url) {
    window.location = 'http://localhost:3001/blog/' + url;
  }

  renderTopics() {
    return this.props.topics.map((x, i)=> 
      <div className={style.TopicSquare}
           onClick={this.goTo.bind(this, x.route())}
           key={"render_topics_" + i}>
        {x.name().toUpperCase()}
      </div>
    );
  }

  render() {
    return (
      <div className={style.Topics}>
        { this.renderTopics() }
      </div>
    );
  }
}

export default Topics;