import React from "react";
import style from "./index.module.scss";

import chevron from './assets/chevron.svg';
import philisophy from './assets/philisophy.svg';

import NormalText from './NormalText';
import BeliefText from './BeliefText';
import SmartAnimation from './SmartAnimation';
import ManMachine from './ManMachine'
import BrainComputer from '../BrainComputer';
import Clock from '../../Components/Clock';

import EmailSignup from '../../Components/EmailSignup';

import GoodRoom from '../../Components/GoodRoom';
import BadRoom from '../../Components/BadRoom';
import AskingComponent from '../../Components/AskingComponent';
import Footer from '../../Components/Footer';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

function Info() {
  return (
    <div>
      <BrowserView>
        <div className={style.Info}>
          <div className={style.InfoCheveron}>
            <img src={chevron} />
          </div>
          <div className={style.InfoContent}>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.InfoTitle}>
              PHILOSOPHY
            </div>
            <div className={style.Spacer}>
            </div>
            <img src={philisophy} className={style.Glasses} />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <SmartAnimation />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <BeliefText />
            <div className={style.Spacer}>
            </div>
            <ManMachine />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <BrainComputer />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <NormalText text={"The way we merge"} />
            <div className={style.Spacer}>
            </div>
            <NormalText text={"intelligence and intellect"} />
            <div className={style.Spacer}>
            </div>
            <NormalText text={"is by"} />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.InfoTitle}>
              ASKING
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <AskingComponent />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <NormalText text={"using your opinions we give you"} />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.InfoTitle}>
              FEEDBACK
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <Clock />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <GoodRoom />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <BadRoom />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <EmailSignup dark={true}/>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={style.InfoMobileSpacer}>
        </div>
        <div className={style.InfoMobile}>
          <div className={style.InfoCheveron}>
            <img src={chevron} />
          </div>
          <div className={style.InfoContent}>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.InfoTitleMobile}>
              PHILOSOPHY
            </div>
            <div className={style.Spacer}>
            </div>
            <img src={philisophy} className={style.Glasses} />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <SmartAnimation />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <BeliefText />
            <div className={style.Spacer}>
            </div>
            <ManMachine />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <BrainComputer />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <NormalText text={"The way we merge"} />
            <div className={style.Spacer}>
            </div>
            <NormalText text={"intelligence and intellect"} />
            <div className={style.Spacer}>
            </div>
            <NormalText text={"is by"} />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.InfoTitleMobile}>
              ASKING
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <AskingComponent />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <NormalText text={"using your opinions we give you"} />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.InfoTitle}>
              FEEDBACK
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <Clock />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <GoodRoom />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <BadRoom />
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.HRBlock}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <EmailSignup dark={true}/>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <div className={style.Spacer}>
            </div>
            <Footer />
          </div>
        </div>
      </MobileView>
    </div>
  );
}

export default Info;