import React from "react";
import style from "./index.module.scss";

import { useSpring, animated } from 'react-spring'
import {
  Keyframes,
  config
} from 'react-spring/renderprops';
import delay from "delay";

function ManMachine() {
  const renderVersus = () => {
    return (
      <div
        className={style.ManMachineStrikeHighlight}>
        vs
      </div>
    );
  }

  const renderWith = () => {
    return (
      <div
        className={style.ManMachineHighlight}>
        with
      </div>
    );
  }

  return (
    <div className={style.ManMachine}>
      <div className={style.ManMachineWords}>MAN </div><div className={style.ManMachineContainer}>{renderVersus()} {renderWith()}</div><div className={style.ManMachineWords}> MACHINE</div>
    </div>
  );
}

export default ManMachine;