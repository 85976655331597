import React from 'react';
import style from './index.module.scss';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import Bar from '../Bar';

function BadRoom() {
  return (
    <div className={style.BadRoom}>
      <BrowserView>
        <div className={style.BadRoomTitle}>
          WHAT DO YOU NEED TO IMPROVE ON
        </div>
        <Bar
          text={"MATERIALS"}
          color={"#D2D2D6"}
          size={22}/>
        <Bar
          text={"REAL ESTATE"}
          color={"#DCDDE0"}
          size={15}/>
        <Bar
          text={"UTILITIES"}
          color={"#E5E5E8"}
          size={28}/>
      </BrowserView>
      <MobileView>
        <div className={style.BadRoomMobileTitle}>
          WHAT DO YOU NEED TO IMPROVE ON
        </div>
        <Bar
          text={"MATERIALS"}
          color={"#D2D2D6"}
          size={22}/>
        <Bar
          text={"REAL ESTATE"}
          color={"#DCDDE0"}
          size={15}/>
        <Bar
          text={"UTILITIES"}
          color={"#E5E5E8"}
          size={28}/>
      </MobileView>
    </div>
  );
}

export default BadRoom;
