import React from 'react';
import style from './index.module.scss';

function MachineSpacer(props) {
  return (
    <div className={style.MachineSpacer}>
    </div>
  );
}

export default MachineSpacer;
