import React from 'react';
import style from './index.module.scss';

import articles from './assets/articles.svg';
import topics from './assets/topics.svg';

import white_articles from './assets/white_articles.svg';
import white_topics from './assets/white_topics.svg';

class Switch extends React.Component {
  renderArticle() {
    if (this.props.selected) {
      return (
        <div className={style.SwitchButton}>
          <img src={white_articles} />
        </div>
      );
    } else {
      return (
        <div className={style.SwitchButtonWhite}
             onClick={this.props.buttonClick.bind(this, true)}>
          <img src={articles} />
        </div>
      );
    }
  }

  renderTopic() {
    if (this.props.selected) {
      return (
        <div className={style.SwitchButtonWhite}
             onClick={this.props.buttonClick.bind(this, false)}>
          <img src={topics} />
        </div>
      );
    } else {
      return (
        <div className={style.SwitchButton}>
          <img src={white_topics} />
        </div>
      );
    }
  }

  render() {
    return (
      <div className={style.SwitchCont}>
        <div className={style.Switch}>
          { this.renderTopic() }
          <div className={style.SwitchSpacer}>
          </div>
          { this.renderArticle() }        
        </div>
      </div>
    );
  }
}

export default Switch;