import React from 'react';
import style from './index.module.scss';

function SmartAnimation() {
  const renderPeople = () => {
    return (
      <span className={style.SmartAnimationHighlightPeople}>
        PEOPLE
      </span>
    );
  }

  const renderComputer = () => {
    return (
      <span className={style.SmartAnimationHighlightComputer}>
        COMPUTERS
      </span>
    );
  }

  return (
    <div className={style.SmartAnimation}>
      <div className={style.SmartAnimationCont}>{renderComputer()}{renderPeople()}</div><div>ARE SMART</div>
    </div>
  );
}

export default SmartAnimation;