import React from 'react';
import style from './index.module.scss';

function Bar(props) {
  return (
    <div>
      <div className={style.Bar}>
        <div
          className={style.BarElement}
          style={{
            background:props.color,
            width:props.size + '%'
          }}>
        </div>
      </div>
      <div className={style.BarLabel}>
        <span className={style.BarSector}>SECTOR:</span> {props.text}
      </div>
    </div>
  );
}

export default Bar;
