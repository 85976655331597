import React from 'react';
import style from './index.module.scss';

import Banner from '../Banner';
import Info from '../Info';

class HomePage extends React.Component {
  render() {
    return (
      <div className={style.HomePage}>
        <Banner/>
        <Info />
      </div>
    );
  }
}

export default HomePage;
