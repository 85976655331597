import React, { useState, useEffect } from "react";
import style from "./index.module.scss";

import { useSpring, animated } from 'react-spring';
import { Keyframes, animated as anim, config  } from 'react-spring/renderprops';
import delay from "delay";


import EmailSignup from "../EmailSignup";
import StockColumn from "../StockColumn";

const length = 36;
const ticker_list = [
  "TSLA",
  "AAPL",
  "GME",
  "AMZN",
  "NIO",
  "MSFT",
  "AMD",
  "FB",
  "IBM",
  "NFLX",
  "GE",
  "SPY",
  "BA",
  "C",
  "GS",
  "BAC"
];

const dela = 5;
const end_delay = 5;
const index = 17;

function randTicker() {
  return ticker_list[Math.floor(Math.random() * ticker_list.length)];
}

function randRange() {
  return Math.floor(Math.random() * 75) + 10;
}

function randStart() {
  return Math.floor(Math.random() * 40) + 140;
}

function randDirection() {
  return Math.floor(Math.random() * 340);
}


function randBool() {
  const direction = Math.floor(Math.random() * 2);
  if (direction == 1) {
    return 'up';
  } else {
    return 'down';
  }
}

function generateStocks() {
  const min_top = 10;
  const max_value = 340;

  var tickers = [
    {
      ticker: "up",
      height: randRange(),
      top: randStart()
    }
  ];

  for (var x = 1; x < length; x++) {
    var ticker;
    var top;
    const direction = randDirection();
    const bar_height = randRange();

    const previous_value = tickers[x - 1];
    var cursor = previous_value["top"];

    if (previous_value["ticker"] == "down") {
      cursor += previous_value["height"];
    }

    if (direction < cursor) {
      ticker = "up";
      top = cursor - bar_height;
    } else {
      ticker = "down";
      top = cursor;
    }

    if (ticker == "up" && top < min_top) {
      ticker = "down";
      top = cursor;
    } else if (ticker == "down" && cursor + bar_height > max_value + min_top) {
      ticker = "up";
      top = cursor - bar_height;
    }

    var peturb = Math.floor(Math.random() * 14) - 7;
    if (peturb < 0) {
      peturb = 0;
    }

    if (ticker == "down") {
      top -= peturb;
    } else {
      top += peturb;
    }

    tickers.push({
      ticker: ticker,
      height: bar_height,
      top: top
    });
  }
  return tickers;
}

function computeExpected(stocks) {
  const measured = stocks[(index - 1)];
  const last = stocks[stocks.length - 1];

  var measured_close = measured.top;
  if (measured.ticker == 'down') {
    measured_close += measured.height;
  }

  var last_close = last.top;
  if (last.ticker == 'down') {
    last_close += last.height;
  }

  if (measured_close > last_close) {
    return 'up';
  } else {
    return 'down';
  }
}

const stocks = generateStocks();
const expected = computeExpected(stocks);

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / -30}px,${y / -30}px,0)`
const trans2 = (x, y) => `translate3d(${x / -20}px,${y / -20}px,0)`

function AppStock() {
  const [ready, setReady] = useState(false);
  const [tickers, setTickers] = useState(stocks);
  const [currentTicker, setCurrentTicker] = useState("");
  const [expectedDirection, setExpectedDirection] = useState(expected);
  const [actualDirection, setActualDirection] = useState("");

  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }));

  const tot_delay = length + dela + end_delay;

  const Sidebar = Keyframes.Spring(async next => {
    await delay(index * 300)
    await next({opacity: 1.0, from: { opacity: 0.0 }, config: config.wobble})
    await delay((tot_delay - index) * 300)
  });

  const Result = Keyframes.Spring(async next => {
    await delay((length + dela) * 300)
    await next({opacity: 1.0, from: { opacity: 0.0 }, config: config.wobble})
    await delay((end_delay) * 300)
  });

  useEffect(() => {
    setCurrentTicker(randTicker());
    setActualDirection(randBool())
    setReady(true);
  });

  const triggerStocks = idx => {
    if (idx == length - 1) {
      const stock = generateStocks()
      setTickers(stock);
      setActualDirection(randBool());
      setCurrentTicker(randTicker());
      setExpectedDirection(computeExpected(stock))
    }
  };

  const renderStocks = () => {
    return tickers.map((x, i) => {
      var idx = i;
      var total_length = length + dela + end_delay;
      if (index <= i) idx += dela;
      return (
        <StockColumn
          ticker={x.ticker}
          height={x.height}
          top={x.top}
          timing={idx}
          highlight={index - 1 <= i}
          length={total_length}
          trigger={triggerStocks}
          key={"stock_column_" + i}
        />
      );
    });
  };

  const renderText = () => {
    return (
      <Sidebar className={style.StockColumn} native>{ (state) => (
        <animated.div style={{...state}}>
          <div className={style.Performance}>
            {"I think "}
            <span className={style.TickerText}>{currentTicker}</span>
            {" is going to go " + actualDirection}
          </div>
        </animated.div>
      )}
      </Sidebar>
    );
  };

  const renderResult = () => {
    const selected_style = (expectedDirection == actualDirection)? style.GoodExpectedPerformance : style.BadExpectedPerformance;
    const prefix_words = (expectedDirection == actualDirection)?"Correct":"Incorrect";
    return (
      <Result className={style.StockColumn} native>{ (state) => (
        <animated.div style={{...state}}>
          <div className={selected_style}>
            {prefix_words}
          </div>
        </animated.div>
      )}
      </Result>
    );
  };

  const renderComponent = () => {
    if (ready) {
      return (
        <div className={style.AppStock} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <animated.div
            className={style.Window}
            style={{ transform: props.xy.interpolate(trans1) }}>
            <div className={style.TopBar}>
              <div className={style.Spacer} />
              <div className={style.Close} />
              <div className={style.Spacer} />
              <div className={style.Minimize} />
              <div className={style.Spacer} />
              <div className={style.Maximize} />
            </div>
            <div className={style.Content}>
              <div className={style.Ticker}>{currentTicker}</div>
              {renderStocks()}
              {renderText()}
              {renderResult()}
            </div>
          </animated.div>
          <div className={style.VSpacer} />
          <animated.div
            style={{ transform: props.xy.interpolate(trans2) }}>
            <EmailSignup />
          </animated.div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  return renderComponent();
}

export default AppStock;
