import React from 'react';
import style from './index.module.scss';

import { useSpring, animated } from 'react-spring'
import {
  Keyframes,
  config
} from 'react-spring/renderprops';
import delay from "delay";

function randBool() {
  return Math.floor(Math.random() * 2);
}

const Blink = Keyframes.Spring(async next => {
  while (true) {
    await delay(300)
    await next({first: randBool(), second: randBool(), third: randBool(), from: { first: 1.0, second: 1.0, third: 1.0 }, config: config.wobble})
    await delay(300)
    await next({first: randBool(), second: randBool(), third: randBool(), from: { first: 1.0, second: 1.0, third: 1.0  }, config: config.wobble})
    await delay(300)
    await next({first: randBool(), second: randBool(), third: randBool(), from: { first: 1.0, second: 1.0, third: 0.0  }, config: config.wobble})
  }
});

function Machine(props) {
  const renderStyle = (trigger) => {
    if (trigger.startPosition == 1) {
      return {
        background:'#B0B0B0',
        boxShadow: 'inset 0 0 3px 0 rgba(0,0,0,0.50)'
      };
    } else {
      return {
        background:'#7DF9FF',
        boxShadow:'0 0 3px 2px #7DF9FF'
      };
    }
  }

  const renderMachineCircle = () => {
    return (
      <Blink className={style.StockColumn} native>{ (state) => (
        <div className={style.MachineLights}>
          <animated.div
            className={style.MachineCircle}
            style={renderStyle(state.first)}></animated.div>
          <animated.div
            className={style.MachineCircle}
            style={renderStyle(state.second)}></animated.div>
          <animated.div
            className={style.MachineCircle}
            style={renderStyle(state.third)}></animated.div>
        </div>
      )}
      </Blink>
    );
  }

  return (
    <div className={style.Machine}>
      {renderMachineCircle()}
      <div className={style.MachineBackPlate}>
        <div className={style.MachineVentContainer}>
          <div className={style.MachineVent}></div>
          <div className={style.MachineVent}></div>
          <div className={style.MachineVent}></div>
        </div>
        <div className={style.MachineCircular}>
        </div>
      </div>
    </div>
  );
}

export default Machine;
