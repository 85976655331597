class Author {
  name() {
    throw new Error("Name method needs to be implemented");
  }
}

class RomiEklund extends Author {
  name() {
    return 'Romi Eklund'
  }
}

const Authors = Object.freeze({
  ROMI_EKLUND: new RomiEklund(),
});

export { Authors }