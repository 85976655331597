import React from "react";
import style from "./index.module.scss";

function NormalText(props) {
  return (
    <div className={style.NormalText}>
      { props.text }
    </div>
  );
}

export default NormalText;