import React from 'react';
import style from './index.module.scss';

import Machine from './Machine';
import MachineSpacer from './MachineSpacer';

function MachineBlinking() {
  return (
    <div className={style.MachineBlinking}>
      <Machine />
      <MachineSpacer />
      <Machine />
      <MachineSpacer />
      <Machine />
    </div>
  );
}

export default MachineBlinking;
