import React from "react";
import style from './index.module.scss';

class Paragraph extends React.Component {
  render() {
    return (
      <div className={style.Paragraph}>
        {this.props.text}
      </div>
    )
  }
}

export default Paragraph;