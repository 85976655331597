import React from 'react';
import style from './index.module.scss';

class Title extends React.Component {
  goHome() {
    window.location = "http://localhost:3001/blog";
  }

  render() {
    return (
      <div className={style.Title}>
        <div className={style.TitleCont}
             onClick={this.goHome.bind(this)}>
          TRADER<span className={style.TitleHighlight}>THOUGHT</span>
        </div>
        <div className={style.TitleBlog}>
          {this.props.subtitle}
        </div>
      </div>
    );
  }
}

export default Title;