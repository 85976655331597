import React from 'react';
import style from './index.module.scss';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import { NavLink } from "react-router-dom";

function Footer(props) {
  const sendTermsAndConditions = () => {
    window.location = '/terms_of_service';
  }

  const sendPrivatePolicy = () => {
    window.location = '/private_policy';
  }

  return (
    <div className={style.Footer}>
      <BrowserView>
        <div className={style.FooterContainer}>
          <div className={style.FooterElement}
               onClick={sendTermsAndConditions}>
            TERMS AND CONDITIONS
          </div>
          <div className={style.FooterTextSpacer}>
            |
          </div>
          <div className={style.FooterElement}
               onClick={sendPrivatePolicy}>
            PRIVATE POLICY
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={style.FooterContainerMobile}>
          <div className={style.FooterElement}
               onClick={sendTermsAndConditions}>
            TERMS AND CONDITIONS
          </div>
          <div className={style.FooterTextSpacerMobile}>
          </div>
          <div className={style.FooterElement}
               onClick={sendPrivatePolicy}>
            PRIVATE POLICY
          </div>
          <div className={style.FooterTextSpacer}>
          </div>
        </div>
      </MobileView>
      <div className={style.FooterCopyRight}>
        TraderThought &#169; 2021
      </div>
    </div>
  );
}

export default Footer;
