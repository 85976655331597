import React from 'react';
import style from './index.module.scss';

class Articles extends React.Component {
	goTo(url) {
		window.location = 'http://localhost:3001/blog/' + url;
	}

	renderArticle() {
		return this.props.articles.map((x, i) => {
			return (
				<div key={"articles_list_" + i}
						 className={style.ArticleListElement}
						 onClick={this.goTo.bind(this, x.route())}>
					<div className={style.ArticleListTitle}>
						{x.title()}
					</div>
					<div className={style.ArticleListSummary}>
						{x.summary()}
					</div>
				</div>
			);
		})
	}

  render() {
    return (
      <div className={style.Articles}>
        {this.renderArticle()}
      </div>
    )
  }
}

export default Articles;