import React from "react";
import style from "./index.module.scss";

import loading from './assets/loading.svg';
import check from './assets/check.svg';
import error from './assets/error.svg';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

class AskingComponent extends React.Component {
  render() {
    return (
      <div className={style.AskingCont}>
        <div className={style.Asking}>
          <BrowserView>
            <div className={style.AskingText}>
              I THINK <span className={style.AskingTextHighlight}>F</span> WILL GO UP <span className={style.AskingTextHighlight}>25%</span> IN THE NEXT TWO WEEKS
            </div>
          </BrowserView>
          <MobileView>
            <div className={style.AskingTextMobile}>
              I THINK <span className={style.AskingTextMobileHighlight}>F</span> WILL GO UP <span className={style.AskingTextMobileHighlight}>25%</span> IN THE NEXT TWO WEEKS
            </div>
          </MobileView>
          <div className={style.AskingFeedbackContainer}>
            <img src={error}/>
          </div>
        </div>
        <div className={style.Asking}>
          <BrowserView>
            <div className={style.AskingText}>
              I THINK <span className={style.AskingTextHighlight}>F</span> WILL GO UP <span className={style.AskingTextHighlight}>25%</span> IN THE NEXT TWO WEEKS
            </div>
          </BrowserView>
          <MobileView>
            <div className={style.AskingTextMobile}>
              I THINK <span className={style.AskingTextMobileHighlight}>F</span> WILL GO UP <span className={style.AskingTextMobileHighlight}>25%</span> IN THE NEXT TWO WEEKS
            </div>
          </MobileView>
          <div className={style.AskingFeedbackContainer}>
            <img src={check}/>
          </div>
        </div>
        <div className={style.Asking}>
          <BrowserView>
            <div className={style.AskingText}>
              I THINK <span className={style.AskingTextHighlight}>F</span> WILL GO UP <span className={style.AskingTextHighlight}>25%</span> IN THE NEXT TWO WEEKS
            </div>
          </BrowserView>
          <MobileView>
            <div className={style.AskingTextMobile}>
              I THINK <span className={style.AskingTextMobileHighlight}>F</span> WILL GO UP <span className={style.AskingTextMobileHighlight}>25%</span> IN THE NEXT TWO WEEKS
            </div>
          </MobileView>
          <div className={style.AskingFeedbackContainer}>
            <img src={error}/>
          </div>
        </div>
      </div>
    );
  }
}

export default AskingComponent;