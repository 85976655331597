import React from "react";
import style from "./index.module.scss";

import MachineBlinking from '../../Components/MachineBlinking';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import brain from './assets/brain.svg';
import spacer from './assets/spacer.svg';

function BrainComputer(props) {
  return (
    <div>
      <BrowserView>
        <div className={style.BrainComputer}>
          <div>
            <img src={brain} />
          </div>
          <div className={style.BrainComputerSpacer}>
            <img src={spacer} />
          </div>
          <div>
            <MachineBlinking />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={style.BrainComputerMobile}>
          <div>
            <img src={brain} />
          </div>
          <div className={style.BrainComputerSpacer}>
            <img src={spacer} />
          </div>
          <div>
            <MachineBlinking />
          </div>
        </div>
      </MobileView>
    </div>
  );
}

export default BrainComputer;