class Topics {
  name() {
    throw new Error("Name method needs to be implemented");
  }

  route() {
    throw new Error("Route method needs to be implemented");
  }
}

class CaseStudy extends Topics {
  name() {
    return 'Case Studies';
  }

  route() {
    return 'case_study'
  }
}

const TopicObj = Object.freeze({
  CASE_STUDY: new CaseStudy()
});

export default TopicObj;