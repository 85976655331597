import React from 'react';

class BlogTemplate {
  constructor() {
    if (this.constructor === BlogTemplate) {
      throw new TypeError("Cannot construct BlogTemplate instances directly");
    }
  }

  author() {
    throw new Error("Author method needs to be implemented");
  }

  title() {
    throw new Error("Title method needs to be implemented");
  }

  tags() {
    throw new Error("Tags method needs to be implemented");
  }

  topic() {
    throw new Error("Topic method needs to be implemented");
  }

  summary() {
    throw new Error("Summary method needs to be implemented");
  }

  text() {
    throw new Error("Text method needs to be implemented");
  }

  route() {
    throw new Error("Route method needs to be implemented");
  }
}

export { BlogTemplate };