import React from 'react';
import style from './index.module.scss';

import clock from './assets/clock.svg';
import minute_ticker from './assets/minute_ticker.svg';
import mini_ticker from './assets/mini_ticker.svg';

function Clock() {
  return (
    <div className={style.Clock}>
      <div className={style.ClockHolder}>
        <img src={clock} />
      </div>
      <div className={style.ClockHolder}>
        <img src={minute_ticker} className={style.rotate} />
      </div>
      <div className={style.ClockHolder}>
        <div className={style.ClockHolderMini}>
          <img src={mini_ticker} className={style.fast_rotate} />
        </div>
      </div>
    </div>
  );
}

export default Clock;
