import React from 'react';
import style from './index.module.scss';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

function EmailSignup(props) {
  const styleCSS = (props.dark)?style.EmailTextDark:style.EmailText;
  const buttonCSS = (props.dark)?style.EmailButtonDark:style.EmailButton;
  return (
    <div>
      <BrowserView>
        <div className={style.EmailSignup}>
          <input
            className={styleCSS}
            placeholder={"Email"}
            type={"text"}/>
          <div className={buttonCSS}>
            Sign Up
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={style.EmailSignupMobile}>
          <input
            className={style.EmailTextMobile}
            placeholder={"Email"}
            type={"text"}/>
          <div className={style.EmailButtonMobile}>
            Sign Up
          </div>
        </div>
      </MobileView>
    </div>
  );
}

export default EmailSignup;
