import React from 'react';
import style from './index.module.scss';

import Title from '../Title';

import arrowLeft from './assets/arrowLeft.svg';

class ArticlePage extends React.Component {
  goHome() {
    window.location = "http://localhost:3001/blog";
  }

  render() {
    return (
      <div className={style.ArticlePage}>
        <div className={style.ArticleArrow}
             onClick={this.goHome.bind(this)}>
          <img src={arrowLeft} />
        </div>
        <Title subtitle={this.props.article.title()} />
        <div className={style.ArticlePageCont}>
          <div className={style.ArticlePageText}>
            {this.props.article.text()}
          </div>
        </div>
      </div>
    )
  }
}

export default ArticlePage;