import React from 'react';
import style from './index.module.scss';

import Articles from '../Articles';
import Switch from '../Switch';
import Title from '../Title';
import Topics from '../Topics';

import articles from '../../../Data/registry';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class BlogHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      "selected": false
    }
  }

  buttonClick(index) {
    this.setState({
      selected: index
    })
  }

  getTags() {
    const tags = articles.map((x)=>
      x.tags()
    );

    const flattened = tags.flat();
    return flattened.filter(onlyUnique);
  }

  getTopics() {
    const topics = articles.map((x)=>
      x.topic()
    );
    return topics.filter(onlyUnique);
  }

  renderTopic() {
    if (!this.state.selected) {
      return (<Topics topics={this.getTopics()}
                      history={this.props.history}/>);
    }
  }

  renderArticle() {
    if (this.state.selected) {
      return (<Articles articles={articles}
                        history={this.props.history}/>);
    }
  }

  render() {
    return (
      <div className={style.BlogContainer}>
        <div className={style.Blog}>
          <Title subtitle={"BLOG"}/>
          <Switch selected={this.state.selected}
                  buttonClick={this.buttonClick.bind(this)}/>
          {this.renderTopic()}
          {this.renderArticle()}
        </div>
      </div>
    )
  }
}

export default BlogHome;