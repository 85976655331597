import React from "react";
import style from "./index.module.scss";

function BeliefText() {
  const renderDont = () => {
    return (
      <div className={style.BeliefTextDont}>
        DON'T
      </div>
    );
  }

  const renderDo = () => {
    return (
      <div className={style.BeliefTextDo}>
        DO
      </div>
    );
  }

  return (
    <div className={style.BeliefText}>
      <div className={style.BeliefTextSpacer}>We </div><div className={style.BeliefTextContainer}>{renderDont()}{renderDo()}</div><div className={style.BeliefTextSpacer}>believe in</div>
    </div>
  );
}

export default BeliefText;