import React from 'react';
import style from './index.module.scss';

class Explorer extends React.Component {
  render() {
    return (
      <div className={style.Explorer}>
        Explorer
      </div>
    );
  }
}

export default Explorer;
